import { defineStore } from "pinia";
import state from "./product.state";
import getters from "./product.getter";
import actions from "./product.action";

const useProductStore = defineStore("product", {
  state,
  getters,
  actions,
});

export default useProductStore;
