import { Product, StoreContext } from "@/stores/product/type.ts";
import { fetchProductListApi } from "@/services/api/product/productApi.ts";
import useLoaderStore from "../loader/loader.store.ts";

export default {
  async fetchProducts(this: StoreContext) {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    const products = await fetchProductListApi();
    this.$state.products = products as Product[];
    loader.changeLoadingStatus({ isLoading: false });
  },
};
