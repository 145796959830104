import { Product } from "@/stores/product/type.ts";

const dummyData: Product[] = [
  {
    id: 1,
    product_code: "PC-001",
    product_name: "Product Alpha",
    standard_start_time: "08:00:00",
    product_description: "A high-quality alpha product.",
    travel_from: "Oslo, Norway",
    organization: "Beta Inc",
    from_radius: 2,
    travel_to: "Oslo, Norway",
    to_radius: 2,
    distance: 70870,
    duration: 108.26666666666667,
    price_inc_vat: 0,
    price_ex_vat: 0,
    tags: ["home", "budget"],
  },
  {
    id: 2,
    product_code: "PC-002",
    product_name: "Product Beta",
    standard_start_time: "09:30:00",
    product_description: "A reliable beta product.",
    travel_from: "Oslo, Norway",
    organization: "Alpha Corp",
    from_radius: 2,
    travel_to: "Jaren, Norway",
    to_radius: 2,
    distance: 70870,
    duration: 108.26666666666667,
    price_inc_vat: 0,
    price_ex_vat: 0,
    tags: ["electronics", "premium"],
  },
];
const fetchProductListApi = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(dummyData);
    }, 1000);
  });
};

export { fetchProductListApi };
